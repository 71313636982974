<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tim.certificate') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-overlay>
                        <b-row>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscal_year')}}
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                <v-select name="fiscal_year_id"
                                  v-model="formData.fiscal_year_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= fiscalYearList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}}
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                <v-select name="org_id"
                                  v-model="formData.org_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= orgList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}}
                              </template>
                              <!-- <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                <v-select name="training_type_id"
                                  v-model="formData.training_type_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainingTypeList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}}
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                <v-select name="training_category_id"
                                  v-model="formData.training_category_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainingCategoryList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}}
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                <v-select name="training_title_id"
                                  v-model="formData.training_title_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainingTitleList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="batch_no"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.batch_no')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.batch_no"
                                :options="batchList"
                                id="batch_no"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no"  rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    disabled
                                    v-model="formData.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                          &nbsp;
                        </div>
                       </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12" v-if="showData">
              <b-overlay :show="loading">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.certificate_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button type="button" v-if="pdfCheck" variant="primary" class="ml-2" @click="pdfDownloadExport">{{ $t('globalTrans.download') }}</b-button>
                      <b-button type="button" v-if="pdfCheck" variant="primary" class="ml-2" @click="pdfExport">{{ $t('globalTrans.print') }}</b-button>
                    </template>
                    <template v-slot:body>
                        <!-- <b-overlay :show="loading"> -->
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(designation)="data">
                                        <span v-if="data.item.designation_id && data.item.not_here_designation == 0 && data.item.profession_type == 1">{{ getDesignation(data.item.designation_id) }}</span>
                                        <span v-else>{{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}</span>
                                        </template>
                                        <template v-slot:cell(work_place)="data">
                                          <span v-if="data.item.profession_type == 1 && data.item.not_here_office == 0">{{ getOfficeName(data.item.officeId) + ', ' + getOrgName(data.item.orgid) }}</span>
                                          <span v-else-if="data.item.not_here_office == 1 && data.item.not_here_office == 1">{{ (($i18n.locale=='bn') ? data.item.other_office_name_bn : data.item.other_office_name) }}</span>
                                          <span v-else-if="data.item.profession_type != 1 && data.item.not_here_office == 1">{{ ($i18n.locale=='bn') ? data.item.other_office_name_bn : data.item.other_office_name }}</span>
                                          <!-- <span v-if="data.item.professional_info.profession_type == 1 && data.item.professional_info.not_here_office == 0">{{ getOfficeName(data.item.professional_info.office_id) + ', ' + getOrgName(data.item.professional_info.org_id) }}</span>
                                          <span v-else-if="data.item.professional_info.profession_type == 1 && data.item.professional_info.not_here_office == 1">{{ (($i18n.locale=='bn') ? data.item.professional_info.office_name_bn : data.item.professional_info.office_name) }}</span>
                                          <span v-else-if="data.item.professional_info.profession_type != 1">{{ ($i18n.locale=='bn') ? data.item.professional_info.other_org_name_bn : data.item.professional_info.other_org_name }}</span> -->
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                            {{ data.item.mobile |mobileNumber }}
                                        </template>
                                        <template v-slot:cell(email)="data">
                                        <span class="capitalize">{{ data.item.email }}</span>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        <!-- </b-overlay> -->
                    </template>
                </iq-card>
              </b-overlay>
            </b-col>
        </b-row>
    <!-- <pre>{{listData}}</pre> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { certificatePDFList, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
      return {
        pdfCheck: false,
        showData: false,
        circularLoading: false,
        loading: false,
        formData: {
          coordinator_id: 0,
          org_id: null,
          fiscal_year_id: null,
          circular_memo_no: '',
          training_type_id: null,
          training_category_id: null,
          training_title_id: null,
          batch_no: 0
        },
        checkData: [],
        item: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        circularList: [],
        allBatchListData: [],
        trainingTypeList: [],
        batchList: [],
        myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
      }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      userTypeList () {
        const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              // { label: this.$t('elearning_iabm.registration_for'), class: 'text-left' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              // { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('elearning_config.training_title'), class: 'text-left' },
              { label: this.$t('elearning_tim.name'), class: 'text-left' },
              { label: this.$t('globalTrans.designation'), class: 'text-left' },
              { label: this.$t('elearning_tpm.work_place'), class: 'text-left' },
              { label: this.$t('globalUserData.mobile_no'), class: 'text-left' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                // { key: 'user_type_name_bn' },
                { key: 'fiscal_year_bn' },
                { key: 'circular_memo_no' },
                // { key: 'org_bn' },
                { key: 'training_title_bn' },
                { key: 'name_bn' },
                { key: 'designation' },
                { key: 'work_place' },
                { key: 'mobile' }
              ]
          } else {
              keys = [
                { key: 'index' },
                // { key: 'user_type_name' },
                { key: 'fiscal_year' },
                { key: 'circular_memo_no' },
                // { key: 'org' },
                { key: 'training_title' },
                { key: 'name' },
                { key: 'designation' },
                { key: 'work_place' },
                { key: 'mobile' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.formData = Object.assign({}, this.formData, {
          fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.formData.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
    },
    mounted () {
        core.index()
    },
    watch: {
      'formData.org_id': function (newValue) {
        this.trainingTypeList = this.getTypeList(newValue)
      },
      'formData.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'formData.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'formData.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
        this.formData.circular_memo_no = ''
        this.formData.batch_no = 0
          this.getCircularList()
        }
      },
      'formData.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'formData.batch_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.formData.circular_memo_no = allBatchListData.circular_memo_no
          }
        }
      },
      'search.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.batch_no = allBatchListData.batch_no
          }
        }
      }
    },
    methods: {
      async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, this.formData)
        this.loading = true
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, certificatePDFList, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loading = false
      },
      async pdfDownloadExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, this.formData)
        this.loading = true

        try {
          const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, certificatePDFList, params)
          var blob = new Blob([result], { type: 'application/pdf' })
          var url = window.URL.createObjectURL(blob)

          // Create an anchor element for direct download
          var a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = 'nata-all-certificate.pdf' // Set the desired filename for the download

          // Trigger a click event on the anchor element to initiate the download
          document.body.appendChild(a)
          a.click()

          // Clean up the anchor element and URL object
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)

          this.loading = false
        } catch (error) {
          this.loading = false
        }
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.formData.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id,
          training_type_id: this.formData.training_type_id,
          training_category_id: this.formData.training_category_id,
          training_title_id: this.formData.training_title_id,
          coordinator_id: this.formData.coordinator_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      },
      getTypeList (orgId) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      },
      getOrgName (id) {
          const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
          if (this.$i18n.locale === 'bn') {
              return trainingType !== undefined ? trainingType.text_bn : ''
          } else {
              return trainingType !== undefined ? trainingType.text_en : ''
          }
      },
      getDesignation (id) {
        const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return designation !== undefined ? designation.text_bn : ''
        } else {
          return designation !== undefined ? designation.text_en : ''
        }
      },
      getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return office !== undefined ? office.text_bn : ''
        } else {
          return office !== undefined ? office.text_en : ''
        }
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async searchData () {
        this.loadData()
      },
      loadData () {
        this.showData = true
        this.loading = true
        const params = Object.assign({}, this.formData)
        RestApi.getData(trainingElearningServiceBaseUrl, certificatePDFList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data))
                this.checkData = response.data.length
                if (this.checkData !== 0) {
                  this.pdfCheck = true
                } else {
                  this.pdfCheck = false
                }
                this.loading = false
            }
            this.loading = false
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const organizationObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.orgid))
          const organizationData = {}
          if (typeof organizationObj !== 'undefined') {
            organizationData.organization = organizationObj.text_en
            organizationData.organization_bn = organizationObj.text_bn
          } else {
            organizationData.organization = ''
            organizationData.organization_bn = ''
          }
          const headingObj = this.$store.state.TrainingElearning.commonObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(item.orgid))
          const headingData = {}
          if (typeof headingObj !== 'undefined') {
            headingData.address = headingObj.address
            organizationData.address_bn = headingObj.address_bn
          } else {
            headingData.address = ''
            headingData.address_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, item, orgData, organizationData, headingData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, customItem)
        })
        return listData
      }
    }
}
</script>
<style>
.wid span {
    display: block;
    width: 100px;
    overflow-wrap: anywhere;
}
</style>
